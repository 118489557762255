<template>
  <div>
    <v-slide-y-transition mode="out-in">
      <form-panel
        :alert-text="alertText"
        :form-steps="formSteps"
        :breadcrumbs="breadcrumbs"
        :status="status"
        :title="title"
      />
    </v-slide-y-transition>
    <v-dialog
      v-model="pdpaDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      max-width="350px"
    >
      <v-card>
        <v-card-title class="headline">
          Personal Data Protection Act (PDPA)
        </v-card-title>
        <v-card-text>
          I acknowledge and agree to the terms and conditions of the XOX
          and consent to the use of my personal data in the manner and
          for the purposes stipulated in the Privacy Notice (also available
          for download at www.xox.com.my).
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click.native="agreePdpa(false)"
          >
            Disagree
          </v-btn>
          <v-btn
            flat
            @click.native="agreePdpa(true)"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="notification"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      max-width="350px"
    >
      <v-card color="info">
        <v-card-title class="headline">
          Notification
        </v-card-title>
        <v-card-text v-html="alertText" />
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click.native="notification = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showJsonPayload"
      v-model="showJsonPayload"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
    >
      <v-card>
        <v-card-title class="headline">
          Submission Payload
        </v-card-title>
        <v-card-text>
          <div v-highlight>
            <pre class="language-javascript">
              <code>
{{ submissionResponse }}
              </code>
            </pre>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click.native="showJsonPayload = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import 'vue-code-highlight/themes/prism-twilight.css'

export default {
  components: {
    FormPanel: () => import(/* webpackChunkName: "formPanel" */ '@/components/FormPanel'),
  },
  data () {
    return {
      alertText: null,
      breadcrumbs: [],
      pdpaDialog: false,
      notification: false,
      showJsonPayload: false,
      status: null,
      submissionResponse: null,
      submissionStep: null,
    }
  },
  computed: {
    // Override this property
    formSteps: function () {
      console.warn('Missing computed.formSteps')
      return {}
    },
  },
  watch: {
    'submissionStep.submit': function (val) {
      val && this.submit()
    },
  },
  mounted: function () {
    Object.entries(this.formSteps).forEach(([stepKey, stepValue]) => {
      if (Object.prototype.hasOwnProperty.call(stepValue, 'submit')) this.submissionStep = this.formSteps[stepKey]

      stepValue.fields && Object.keys(stepValue.fields).forEach(fieldKey => {
        Object.prototype.hasOwnProperty.call(stepValue.fields[fieldKey], 'component') ||
          this.$set(stepValue.fields[fieldKey], 'component',
            () => import(/* webpackChunkName: "readOnlyField" */ '@/components/ReadOnlyField'))
        Object.prototype.hasOwnProperty.call(stepValue.fields[fieldKey], 'value') ||
          this.$set(stepValue.fields[fieldKey], 'value', null)
        Object.prototype.hasOwnProperty.call(stepValue.fields[fieldKey], 'props') ||
          this.$set(stepValue.fields[fieldKey], 'props', { rules: [] })
        Object.prototype.hasOwnProperty.call(stepValue.fields[fieldKey], 'error') ||
          this.$set(stepValue.fields[fieldKey], 'error', null)
        Object.prototype.hasOwnProperty.call(stepValue.fields[fieldKey].props, 'rules') ||
          this.$set(stepValue.fields[fieldKey].props, 'rules', [])
      })
    })
  },
  methods: {
    agreePdpa: function (val) {
      this.pdpaDialog = false
    },
    // Override this method
    submit: function () {
      console.warn('Missing submit handler!')
    },
    isImpersonated: function () {
      return this.$store.state.security.impersonate !== null
    },
    showSubmissionResponse: function (response) {
      this.submissionResponse = response
      this.showJsonPayload = true
    },
  },
}
</script>
